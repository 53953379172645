import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import DesktopImg from '../images/email-landing/pendant-landing.jpg';

const GlobalStyle = createGlobalStyle`
html, body, #___gatsby {
    height: 100%;
}

body {
    margin: 0px;
}

#gatsby-focus-wrapper {
    height: 100%;
}
`;

const Container = styled.main`
  max-width: 1600px;
  width: calc(100% - 300px);
  margin: 40px 150px 100px 150px;

  @media (max-width: 1000px) {
    width: calc(100% - 40px);
    margin: 150px auto 100px auto;
  }

  @media (max-width: 500px) {
    margin: 120px auto 80px auto;
  }

  @media (min-width: 1600px) {
    margin: 40px auto 100px auto;
  }
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) =>
    props.layout === "grid" ? "1fr 1fr" : "1fr"};
  grid-template-rows: 1fr 1fr;
  grid-gap: 100px;
  padding-bottom: 40px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 60px;
    grid-template-rows: 1fr 1fr 1fr;
  }
`;

const Video = styled.video`
  width: 100%;
  grid-column: 1 / 3;
  @media (max-width: 800px) {
    grid-column: 1;
  }
`;

const LandingImage = styled.img`
  width: 80%;
  justify-self: center;
  grid-column: 1 / 3;
  @media (max-width: 800px) {
    grid-column: 1;
    width: 100%;
  }
`

const IndexPage = ({ data }) => {
  const { frontmatter } = data.allMarkdownRemark.edges[0].node;
  return (
    <>
      <GlobalStyle />
      <Container>
        <Grid layout={"grid"}>
          {/* <Video muted autoPlay loop playsInline>
            <source src={frontmatter.video.publicURL} type="video/mp4" />
          </Video> */}
          <LandingImage src={DesktopImg} />
          {frontmatter && frontmatter.images && frontmatter.images.length > 0 && (
            <>
              <Link to="/collection/all">
                <Img
                  fluid={frontmatter.images[0].childImageSharp.fluid}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              </Link>
              <Link to="/collection/all">
                <Img
                  fluid={frontmatter.images[1].childImageSharp.fluid}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              </Link>
              <Link to="/collection/all">
                <Img
                  fluid={frontmatter.images[2].childImageSharp.fluid}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              </Link>
              <Link to="/collection/all">
                <Img
                  fluid={frontmatter.images[3].childImageSharp.fluid}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              </Link>
              <Link to="/collection/all">
                <Img
                  fluid={frontmatter.images[4].childImageSharp.fluid}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              </Link>
              <Link to="/collection/all">
                <Img
                  fluid={frontmatter.images[5].childImageSharp.fluid}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/homepage/" } }) {
      edges {
        node {
          frontmatter {
            images {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            video {
              publicURL
            }
          }
        }
      }
    }
  }
`;
